<template>
  <v-container
    id="timetablelist"
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          color="success"
          icon="mdi-badge-account"
        >
          <template v-slot:after-heading>
            <v-tooltip
              right
              max-width="50%"
            >
              <template v-slot:activator="{ on }">
                <div
                  class="card-title font-weight-light"
                  v-on="on"
                >
                  Группы
                  <v-icon
                    class="help-pointer"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </div>
              </template>
              <span>Группы</span>
            </v-tooltip>
          </template>

          <v-card-text
            class="pt-0"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
                class="pt-0 pb-0"
              >
                <v-row>
                  <v-col
                    class="pa-0 ma-0"
                  >
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск"
                      single-line
                      hide-details
                    />
                  </v-col>
                  <v-col
                    class="pa-0 ma-0"
                    cols="auto"
                  >
                    <v-icon
                      title="Очистить поиск"
                      class="help-pointer pt-5 pb-5 pl-2 pr-5"
                      @click="search = ''"
                    >
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0 pl-0"
              >
                <v-btn
                  class="ma-1"
                  color="secondary"
                  rounded
                  @click="createTimetable"
                >
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Добавить
                </v-btn>
              </v-col>
              <v-col
                class="pt-0 pb-0"
              />

              <v-col
                cols="12"
                md="auto"
                class="pt-0 pb-0"
              >
                <v-switch
                  v-model="onlyArchived"
                  class="mt-0 mb-0"
                  :label="onlyArchivedLabel"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Загрузка..."
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page.sync="itemsPerPage"
            :page.sync="page"
            :sort-by="['order']"
            :sort-desc="[false]"
            class="timetables"
            @click:row="singleTimetable"
          >
            <template v-slot:item.status="{ item }">
              <v-icon v-if="item.status">
                mdi-eye
              </v-icon>
              <v-icon v-else>
                mdi-eye-off
              </v-icon>
            </template>
            <template v-slot:item.schedules="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                <v-chip
                  color="secondary"
                  class="group-chip"
                  small
                >
                  {{ schedule.course.name }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.time_pn="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.pn, schedule.time_pn) }}
              </div>
            </template>
            <template v-slot:item.time_vt="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.vt, schedule.time_vt) }}
              </div>
            </template>
            <template v-slot:item.time_sr="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.sr, schedule.time_sr) }}
              </div>
            </template>
            <template v-slot:item.time_ch="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.ch, schedule.time_ch) }}
              </div>
            </template>
            <template v-slot:item.time_pt="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.pt, schedule.time_pt) }}
              </div>
            </template>
            <template v-slot:item.time_sb="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.sb, schedule.time_sb) }}
              </div>
            </template>
            <template v-slot:item.time_vs="{ item }">
              <div
                v-for="schedule in item.schedules"
                :key="schedule.id"
              >
                {{ dayTime(schedule.vs, schedule.time_vs) }}
              </div>
            </template>
            <template v-slot:item.order="{ item }">
              <v-btn
                color="primary"
                small
                @click.stop="singleTimetable(item)"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-btn
                class="ml-5"
                color="secondary"
                small
                @click.stop="orderUp(item.id)"
              >
                <v-icon>
                  mdi-arrow-up
                </v-icon>
              </v-btn>
              <v-btn
                color="secondary"
                small
                @click.stop="orderDown(item.id)"
              >
                <v-icon>
                  mdi-arrow-down
                </v-icon>
              </v-btn>
            </template>
            <!--
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ archived: !item.status }"
                  @click="singleTimetable(item)"
                >
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <td><span v-if="item.course">{{ item.course.name }}</span></td>
                  <td><span v-html="item.timetableSummary" /></td>
                  <td>{{ item.comment }}</td>
                  <td>

                  </td>
                </tr>
              </tbody>
            </template>
            -->
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .v-data-table tr, .v-data-table td {
    cursor: pointer !important;
  }

  .help-pointer {
    cursor: pointer;
  }

  .v-data-table__wrapper table tr td {
    cursor: pointer;
  }

  .archived {
    opacity: 0.5;
    text-decoration: line-through;
  }

  .help-pointer {
    cursor: pointer;
  }
  .group-chip {
    height: auto !important;
    margin-bottom: 1px !important;
    display: table-cell;
    white-space: nowrap;
  }
</style>

<script>
  import timetablesApi from '../services/TimetablesApi'
  import CRMStorage from '../services/CRMStorage'

  export default {
    data () {
      return {
        search: CRMStorage.getItem('TL_search', ''),
        page: 1,
        itemsPerPage: CRMStorage.getItem('TL_itemsPerPage', 10),
        onlyArchived: CRMStorage.getItem('TL_onlyArchived', false),
        headers: [
          {
            sortable: false,
            text: '',
            value: 'status',
            filter: value => {
              if (this.onlyArchived) {
                return value === 0
              }
              return value === 1
            },
          },
          {
            sortable: true,
            text: 'Id',
            value: 'id',
          },
          {
            sortable: true,
            text: 'Группа',
            value: 'name',
          },
          {
            sortable: true,
            text: 'Курс',
            value: 'schedules',
          },
          {
            sortable: true,
            text: 'ПН',
            value: 'time_pn',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ВТ',
            value: 'time_vt',
            align: 'center',
          },
          {
            sortable: true,
            text: 'СР',
            value: 'time_sr',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ЧТ',
            value: 'time_ch',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ПТ',
            value: 'time_pt',
            align: 'center',
          },
          {
            sortable: true,
            text: 'СБ',
            value: 'time_sb',
            align: 'center',
          },
          {
            sortable: true,
            text: 'ВС',
            value: 'time_vs',
            align: 'center',
          },
          {
            sortable: true,
            text: 'Действия',
            value: 'order',
          },

        ],
        items: [],
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
      }
    },

    computed: {
      onlyArchivedLabel () {
        if (this.items.length < 1) {
          return 'Показать архивные'
        }
        return 'Показать архивные (' + this.items.filter((item) => item.status === 0).length + ')'
      },
    },
    watch: {
      search: function (val) {
        this.page = 1
        CRMStorage.setItem('TL_search', val)
      },
      itemsPerPage: function (val) {
        CRMStorage.setItem('TL_itemsPerPage', val)
      },
      page: function (val) {
        CRMStorage.setItem('TL_page', val)
      },
      onlyArchived: function (val) {
        this.page = 1
        CRMStorage.setItem('TL_onlyArchived', val)
      },
      items: {
        handler () {
          this.page = +CRMStorage.getItem('TL_page', 1)
          setTimeout(function () {
            window.scrollTo(0, +CRMStorage.getItem('TL_scrollY', 0))
          }, 100)
        },
        deep: true,
      },
    },

    mounted () {
      document.title = 'Группы | ' + process.env.VUE_APP_NAME
      if (!this.itemsPerPage) this.itemsPerPage = 10
      this.fetchData()
    },
    methods: {
      singleTimetable (item) {
        CRMStorage.setItem('TL_scrollY', window.scrollY)
        this.$router.push('/timetable/' + item.id)
      },
      createTimetable () {
        CRMStorage.setItem('TL_scrollY', window.scrollY)
        this.$router.push('/timetable/create')
      },
      handleError (errorResponse) {
        let data = errorResponse.response.data
        if (errorResponse.response.status === 422) {
          this.fieldsErrors = data
        } else if (errorResponse.response.status === 401) {
          this.error = 'Неверный логин или пароль! Войдите в систему заново!'
        } else if (data.message) {
          this.error = data.message
        } else {
          this.error = JSON.stringify(data, null, 2)
        }
        this.loading = false
      },
      orderUp (id) {
        this.loading = true
        timetablesApi
          .orderUp(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      orderDown (id) {
        this.loading = true
        timetablesApi
          .orderDown(id)
          .then(response => {
            this.fetchData()
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      fetchData () {
        this.loading = true
        timetablesApi
          .fetchTimetables()
          .then(response => {
            this.items = response
            this.loading = false
          })
          .catch(error => {
            this.handleError(error)
          })
      },
      dayTime (dayIsActive, time) {
        if (dayIsActive && time) {
          return time.substring(0, 5)
        }
        return '-'
      },
      imageLink (id) {
        return process.env.VUE_APP_OLD_URL + 'i/100/100/' + id
      },
    },
  }
</script>
