import axios from 'axios'

export default {
  fetchActiveTimetables () {
    return axios.get('timetables?status=1&expand=name').then(response => {
      return response.data
    })
  },

  fetchTimetables () {
    return axios.get('timetables?expand=schedules,schedules.course').then(response => {
      return response.data
    })
  },

  fetchTimetablesByDates (start, end) {
    return axios.get('timetables/bydates?start=' + start + '&end=' + end).then(response => {
      return response.data
    })
  },

  fetchTeacherTimetablesByDates (teacherId, start, end) {
    return axios.get('timetables/bydates?teacher_id=' + teacherId + '&start=' + start + '&end=' + end).then(response => {
      return response.data
    })
  },

  fetchTimetableByDates (id, start, end) {
    return axios.get('timetables/bydates?timetable_id=' + id + '&start=' + start + '&end=' + end).then(response => {
      return response.data
    })
  },

  fetchTimetable (id) {
    return axios.get('timetables/' + id + '?expand==schedules,schedules.course').then(response => {
      return response.data
    })
  },

  createTimetable (timetable) {
    return axios.post('timetables', timetable).then(response => {
      return response.data
    })
  },

  updateTimetable (client) {
    return axios.put('timetables/' + client.id, client).then(response => {
      return response.data
    })
  },

  deleteTimetable (id) {
    return axios.delete('timetables/' + id).then(response => {
      return response.data
    })
  },

  orderUp (id) {
    return axios.post('timetables/order', { id: id, move: 'up' }).then(response => {
      return response.data
    })
  },

  orderDown (id) {
    return axios.post('timetables/order', { id: id, move: 'down' }).then(response => {
      return response.data
    })
  },

  changeTimetable (fromId, toId, clientId) {
    return axios.post('timetables/change-timetable', { from_timetable_id: fromId, to_timetable_id: toId, client_id: clientId }).then(response => {
      return response.data
    })
  },
}
